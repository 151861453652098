// 教員の配列をフルネームかどうかでソートする関数
export const teacherFullnameSort = (teachers) => {
    // teachers の名字のみのものを後に表示するために、配列を分けて後ろに追加
    // 名字のみのものは、" " または "." で区切られていないものとする
    let full_name_teachers = [];
    let last_name_teachers = [];
    for (let teacher of teachers) {
      if (teacher.match(/^[^ .]+$/)) {
        last_name_teachers.push(teacher);
      } else {
        full_name_teachers.push(teacher);
      }
    }
    teachers = full_name_teachers.concat(last_name_teachers);
    return teachers;
  };