// 入力値が変化したときの処理
export const onSearchChange = (
    e,
    obj,
    props,
    state,
    setState,
    CourseChangeAutoComplete,
    teacherAutoComplete
  ) => {
    const { name, searchQuery } = obj;
    const univId = props.match.params.univ_id;

    switch (name) {
      case "course":
        // 講義名が空白の場合は、teachers_of_courseを空にする
        if (searchQuery.length === 0) {
          setState({
            ...state,
            options: {
              ...state.options,
              results: {
                ...state.options.results,
                [name]: [],
              },
            },
            loading: {
              ...state.loading,
              [name]: false,
            },
            teachers_of_course: [],
          });
          break;
        }
        CourseChangeAutoComplete(univId, name, searchQuery);
        break;

      case "teacher":
        if (searchQuery.length === 0) {
          setState({
            ...state,
            options: {
              ...state.options,
              results: {
                ...state.options.results,
                [name]: [],
              },
            },
            loading: {
              ...state.loading,
              [name]: false,
            },
            teachers_of_course:
              state.input_select.course.value === ""
                ? []
                : state.teachers_of_course,
          });
          break;
        }
        teacherAutoComplete(univId, name, searchQuery);
        break;

      default:
        break;
    }
  };