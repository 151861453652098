import React from "react";

import Review from "./index";
import { Image } from "semantic-ui-react";
import { gaEventAd } from "../../../api/GA";
import AdApi from "../../../api/v2/AdApi";

class Ad extends React.Component {
  click = () => {
    const { alt, href } = this.props;
    gaEventAd(href, alt);
  };
  render() {
    const { alt, src, href, maxHeight } = this.props;
    return (
      <div style={{ textAlign: "center" }}>
        <a href={href} target="_blank" onClick={() => this.click()}>
          <Image
            alt={alt}
            src={src}
            style={{
              cursor: "pointer",
              display: "inline-block",
              maxHeight: `${maxHeight}px`,
            }}
          />
        </a>
      </div>
    );
  }
}
const shuffleArray = (array) => {
  if (array.length === 0) return array;
  const cloneArray = [...array];

  const result = cloneArray.reduce((_, cur, idx) => {
    let rand = Math.floor(Math.random() * (idx + 1));
    cloneArray[idx] = cloneArray[rand];
    cloneArray[rand] = cur;
    return cloneArray;
  });

  return result;
};
export default class ReviewList extends React.Component {
  state = {
    ads: [],
  };
  componentDidMount() {
    const adApi = new AdApi();
    adApi
      .getAds()
      .then((ads) => {
        const { univ_id } = this.props;
        this.setState({
          ads: ads.filter(
            (ad) =>
              !ad.is_fixed &&
              (ad.limited_to.length === 0 || ad.limited_to.includes(univ_id))
          ), // 全体向けとその大学向けの広告を取得
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const {
      review_list = [],
      user_id = "abcdef",
      univ_id = "nagoya_u",
      deletePost = (id) => {
        console.log("<ReviewList> deletePost: " + id);
      },
      pushHistory = (uri) => {
        console.log("<ReviewList> pushHistory: " + uri);
      },
      loading = false,
      disabled = false,
      clickable = false,
      withAd = false,
      limited = false,
    } = this.props;
    const { ads = [] } = this.state;
    const shuffleAds = shuffleArray(ads);
    const adsList = [];
    shuffleAds.map((ad, key) => {
      const adData = (
        <Ad
          key={ad.id}
          src={ad.image_url.url}
          alt={ad.event_label}
          href={ad.href}
          maxHeight={80}
        />
      );
      adsList.push(adData);
    });
    const reviewLength = review_list?.length;
    const adInterval = 5;
    if (!loading) {
      return (
        <div>
          {review_list.map((item, key) => (
            <>
              <Review
                key={key}
                author={item.author}
                review={item.review}
                comments={item.comments}
                pushHistory={pushHistory}
                deletePost={() => deletePost(item.review.review_id)}
                is_posted_by_user={user_id === item.author.user_id}
                disabled={disabled}
                clickable={clickable}
                limited={limited}
              />
              {withAd && (key + 1) % adInterval === 0 && adsList.pop()}
              {withAd &&
                key === reviewLength - 1 &&
                adsList.map((ad, key) => {
                  return <>{ad}</>;
                })}
            </>
          ))}
        </div>
      );
    } else {
      return <Review loading />;
    }
  }
}