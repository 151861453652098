// TODO: 絶対パスに対応
// import UNIV from "@/constants/univ";
import UNIV from "../../../../constants/univ";

// univ_id に対応する大学の科目区分を返す
export const createCourseGroupItem = (univ_id) => {
    return (
      UNIV[univ_id]?.course_group
        ? UNIV[univ_id]?.course_group
        : ["学部授業", "大学院授業"]
    )
      .concat(["その他", "不明"])
      .map((item) => {
        return { key: item, value: item, text: item };
      });
  };

// univ_id に対応する大学の学期を返す
export const createSemesterItem = (univ_id) => {
  return (
    UNIV[univ_id]?.periods
      ? UNIV[univ_id].periods
      : ["前期", "後期"]
  )
    .concat(["その他", "不明"])
    .map((item) => {
      return { key: item, value: item, text: item };
    });
};
