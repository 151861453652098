import React from "react";
import { Helmet } from "react-helmet";
import { Auth } from "aws-amplify";

import {
  Button,
  Form,
  Header,
  Progress,
  Container,
  Grid,
  Segment,
} from "semantic-ui-react";
import { FaTrophy, FaTable } from "react-icons/fa";

import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";
import Landing from "../../organisms/Landing/index";
import Searchbox from "../../molecules/Searchbox/index";

import UNIV from "../../../constants/univ";
import * as ROUTES from "../../../constants/routes";
import ReviewApi from "../../../api/v2/ReviewApi";
import ApplicantApi from "../../../api/v2/ApplicantApi";
import NewsApi from "../../../api/v2/NewsApi";

import { gaEventTimeTable, gaPageView } from "../../../api/GA";
import { convertReviewSchemaToReview } from "../../../api/v2/ApiUtils";
import { FeedList } from "../../organisms/feeds/FeedList";
import { SymbolButton } from "../../atoms/buttons/SymbolButton";
// TODO: 絶対パスに対応
// import ReviewUserRanking from "@/components/organisms/ReviewUserRanking";
import ReviewUserRanking from "../../organisms/ReviewUserRanking"

export default class AppHomeTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicant_count: 0,
      review_count: 0,
      loading_applicant_count: true,
      loading_review_count: true,
      news: [],
      newPosts: [], // 新着投稿
    };
  }

  componentDidMount = () => {
    const { univ_id = "" } = this.props.match.params;
    gaPageView("/app/" + univ_id);
    this.getApplicantCount(univ_id);
    this.getReviewCount(univ_id);

    // お知らせを取得
    const newsApi = new NewsApi();
    newsApi.getNews().then((news) => {
      news = news.filter(
        (item) =>
          item.limited_to.length === 0 || item.limited_to.includes(univ_id) // 非限定公開 or 公開対象なら表示
      );
      this.setState({
        news: news,
      });
    });

    // 新着投稿を取得
    const newPostLimit = 3; // 新着投稿の表示上限数
    const reviewApi = new ReviewApi();
    reviewApi.getUnivReviews(univ_id).then((reviews) => {
      this.setState({
        newPosts: reviews
          .slice(0, newPostLimit)
          .map((review) => convertReviewSchemaToReview(review)),
      });
    });
    Auth.currentAuthenticatedUser().then((user) => {
      this.setState({ currentUser: user });
    });
  };

  pushHistory = (uri) => {
    if (this.props.history) {
      this.props.history.push(uri);
    }
  };
  getApplicantCount = () => {
    const { univ_id = "" } = this.props.match.params;
    const applicantApi = new ApplicantApi();
    applicantApi
      .getUnivApplicantsCount(univ_id)
      .then((res) => {
        const applicant_count = res.count;
        this.setState({
          applicant_count: parseInt(applicant_count),
          loading_applicant_count: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getReviewCount = (univ_id) => {
    const reviewApi = new ReviewApi();
    reviewApi
      .getUnivReviewsCount(univ_id)
      .then((count) => {
        this.setState({
          review_count: parseInt(count),
          loading_review_count: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getGoalReviewCount = (review_count) => {
    // 3, 10, 30, 100, 300, 1000, 3000, 10000, ...
    const digits = String(review_count).length;
    const offset = 10 ** (digits - 1);
    if (review_count < 3 * offset) {
      return 3 * offset;
    } else {
      return 10 * offset;
    }
  };

  render() {
    const { univ_id = "nagoya_u" } = this.props.match.params;
    const { ...otherProps } = this.props;
    const is_status_open = UNIV[univ_id].status
      ? UNIV[univ_id].status === "open"
      : false;
    const { currentUser, news } = this.state;
    return (
      <div {...otherProps}>
        <Helmet>
          <title>楽単らくだ for {UNIV[univ_id].name}</title>
          <meta
            name="description"
            content={
              "「楽単らくだ」は、履修選択に悩める" +
              UNIV[univ_id].name +
              "の学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
            }
          />
          <meta name="robots" content="index" />
        </Helmet>
        <Navbar
          style={{ marginBottom: "24px" }}
          univ_id={univ_id}
          subtitle={"for " + UNIV[univ_id].name}
          show_pusher={true}
          show_control={true}
          show_action={is_status_open}
          control={
            is_status_open ? (
              <Form>
                <Form.Field>
                  <Searchbox
                    univ_id={univ_id}
                    location={this.props.location}
                    pushHistory={this.pushHistory}
                    showDetail
                  />
                </Form.Field>
              </Form>
            ) : this.state.applicant_count >= 200 ? (
              <div style={{ textAlign: "center" }}>
                <Header as="h2">
                  近日公開予定
                  <Header.Subheader style={{ marginTop: "4px" }}>
                    「楽単らくだ for {UNIV[univ_id].name}」は近日公開予定です。
                  </Header.Subheader>
                </Header>
                <div style={{ marginBottom: "32px" }}>
                  <Progress
                    percent={this.state.applicant_count / 200}
                    progress
                    indicating
                  >
                    {this.state.applicant_count} 件の評価
                  </Progress>
                </div>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Header as="h2">
                  利用者募集中
                  <Header.Subheader>
                    200件以上の評価が集まると 「楽単らくだ for{" "}
                    {UNIV[univ_id].name}
                    」が公開されます。
                  </Header.Subheader>
                </Header>
                {!this.state.loading_applicant_count &&
                  this.state.applicant_count >= 0 && (
                    <div style={{ marginBottom: "32px" }}>
                      <Progress
                        percent={this.state.applicant_count / 200}
                        progress
                        indicating
                      >
                        {this.state.applicant_count} 件の評価
                      </Progress>
                    </div>
                  )}
                <Button
                  primary
                  onClick={() => {
                    window.location.href =
                      "https://forms.gle/pJaWWYiMDBy3srGJ8";
                  }}
                >
                  投稿する
                </Button>
              </div>
            )
          }
          pushHistory={this.pushHistory}
        />
        <Container text style={{ textAlign: "center" }}>
          {news.length > 0 && (
            <table
              style={{ margin: "0px auto", maxWidth: "400px" }}
              cellSpacing={2}
              cellPadding={0}
            >
              <tbody>
                {news.map((item) => {
                  const date = new Date(item.date);
                  const formattedDate = `${date.getFullYear()}.${
                    date.getMonth() + 1
                  }.${date.getDate()}`;
                  return (
                    <tr
                      style={{ lineHeight: "1", textAlign: "left" }}
                      key={item.id}
                    >
                      <td
                        style={{
                          paddingRight: "14px",
                          verticalAlign: "top",
                          color: "gray",
                        }}
                      >
                        <small>{formattedDate}</small>
                      </td>
                      <td>
                        <small
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        ></small>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {
            <Grid
              columns={2}
              centered
              style={{ margin: "0px auto", maxWidth: "400px" }}
            >
              <Grid.Column style={{ textAlign: "center", paddingLeft: 0 }}>
                <SymbolButton
                  icon={<FaTrophy size={30} color="gray" />}
                  label="講義ランキング"
                  onClick={() => {
                    gaEventTimeTable("HomePanel");
                    this.props.history.push(
                      ROUTES.APP.RANKING.COURSE.replace(":univ_id", univ_id)
                    );
                  }}
                  disabled={univ_id !== "nagoya_u"}
                />
              </Grid.Column>
              <Grid.Column style={{ textAlign: "center", paddingRight: 0 }}>
                <SymbolButton
                  icon={<FaTable size={30} color="gray" />}
                  label="時間割自動作成"
                  onClick={() => {
                    gaEventTimeTable("HomePanel");
                    this.props.history.push(
                      ROUTES.APP.TIMETABLE.ROOT.replace(":univ_id", univ_id)
                    );
                  }}
                />
              </Grid.Column>
            </Grid>
          }
          <div style={{ margin: "6px auto 32px", maxWidth: "400px" }}>
            <Segment>
              <ReviewUserRanking />
            </Segment>
          </div>
        </Container>
        {/* {
          // レビュー数バー
          is_status_open && !this.state.loading_review_count ? (
            <Container text>
              <Progress
                percent={
                  Math.round(
                    (this.state.review_count /
                      this.getGoalReviewCount(this.state.review_count)) *
                      100 *
                      10
                  ) / 10
                }
                progress
                indicating
              >
                現在のレビュー数{this.state.review_count}件／目標
                {this.getGoalReviewCount(this.state.review_count)}件
              </Progress>
            </Container>
          ) : null
        } */}
        <Landing univ_id={univ_id}>
          {
            // 新着投稿
            this.state.newPosts.length > 0 && ( // 新着投稿が取得できていれば表示
              <Container text>
                <Header
                  as="h1"
                  textAlign="center"
                  style={{ margin: "48px 0px 24px" }}
                >
                  新着投稿
                  <Header.Subheader>
                    楽単らくだには、日々多くの学生からの授業情報が寄せられています。
                  </Header.Subheader>
                </Header>
                <FeedList
                  feeds={this.state.newPosts.map((item) => {
                    return { type: "review", data: item };
                  })}
                  disabled
                  // limited={currentUser ? false : true} // 新着投稿はログインしていなくても見れるようにする
                  limited={false}
                />
              </Container>
            )
          }
        </Landing>
        <Footer univ_id={univ_id} pushHistory={this.pushHistory} />
      </div>
    );
  }
}