import React from "react";

import { Search } from "semantic-ui-react";

// 廃止予定
export default class SearchboxView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      onSearchChange = (e, obj) => {
        console.log("<Searchbox>: onSearchChange");
        console.log(obj);
      },
      onResultSelect = (e, obj) => {
        console.log("<Searchbox>: onResultSelect");
        console.log(obj);
      },
      results = [
        {
          title: "基礎セミナー",
          description: "全学教養科目",
          price: "10件",
          type: "teacher",
          id: 0,
        },
        {
          title: "田中 太郎",
          description: "教員",
          price: "10件",
          type: "course",
          id: 0,
        },
      ],
      value = "",
      placeholder = "例: 田中太郎, 基礎セミナー",

      loading = false,
      ...otherProps
    } = this.props;
    return (
      <>
        <Search
          onSearchChange={onSearchChange}
          onResultSelect={onResultSelect}
          loading={loading}
          results={results}
          value={value}
          placeholder={placeholder}
          {...otherProps}
        />
      </>
    );
  }
}