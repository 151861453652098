import React from "react";

import styles from "./style.module.scss";

import { gaEventAd } from "../../../api/GA";

export default class Ad extends React.Component {
  constructor(props) {
    super(props);
    this.url = this.props.url;
    this.label = this.props.label;
    this.src = this.props.src;
  }

  click = () => {
    let url = this.url;
    let label = this.label;
    if (!url) return;
    gaEventAd(url, label);
  };

  render() {
    if (!this.url) {
      return null;
    } else {
      return (
        <a href={this.url} onClick={() => this.click()}>
          <div className={styles.ad}>
            <img src={this.src} alt="" />
          </div>
        </a>
      );
    }
  }
}