import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import UserApi from "../../../api/v2/UserApi";
import * as ROUTES from "../../../constants/routes";

// ログイン後に行いたい処理を実行するコンポーネント
const AfterLoginAction = (props) => {
  const { children } = props;
  const history = useHistory();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const userId = user.attributes.sub;
        const userApi = new UserApi();
        userApi
          .getUser(userId)
          .then((user) => {
            // 学科・専攻が未登録の場合、プロフィール編集画面にリダイレクト
            if (!user.major) {
              history.push(ROUTES.APP.USER.EDIT.replace(":user_id", userId));
              window.alert(
                "学科・専攻 が未設定です。プロフィール編集から設定してください。"
              );
            }
          })
          .catch((err) => {
            // ユーザがDBに保存されていなければ、ユーザ登録画面にリダイレクト
            if (err.response && err.response.status === 404) {
              history.push(ROUTES.APP.REGISTER);
            } else {
              // 404以外の場合
              console.log(err);
            }
          });
      })
      .catch(() => null);
  }, []);

  return children;
};
export default AfterLoginAction;