import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";
import Navbar from "../../organisms/Navbar";
import Footer from "../../organisms/Footer";

import systemApi from "../../../api/v2/SystemApi";

export const MaintenancePageTemplate = () => {
  const [message, setMessage] = useState("");
  const pushHistory = () => null;

  useEffect(() => {
    systemApi
      .getSystemSettings()
      .then((settings) => {
        setMessage(settings.maintenance_message);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>楽単らくだ</title>
        <meta
          name="description"
          content={
            "「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
          }
        />
        <meta name="robots" content="index" />
      </Helmet>
      <Navbar
        style={{ marginBottom: "24px" }}
        subtitle={"授業評価"}
        show_pusher={false}
        show_control={false}
        show_action={false}
        pushHistory={pushHistory}
      />
      <Container>
        <div style={{ textAlign: "center", height: "30vh" }}>
          <h1>ただいまメンテナンス中です🐫</h1>
          <p>大変申し訳ございませんが、しばらくお待ちください。</p>
          <p
            style={{ marginTop: "24px" }}
            dangerouslySetInnerHTML={{
              __html: message.replace(/\n/g, "<br />"),
            }}
          />
        </div>
      </Container>
      <Footer pushHistory={pushHistory} show_sitemap={false} />
    </>
  );
};