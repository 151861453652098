import React from "react";
import request from "superagent";

import SearchboxView from "./SearchboxView";
import * as ROUTES from "../../../constants/routes";
import CourseApi from "../../../api/v2/CourseApi";
import TeacherApi from "../../../api/v2/TeacherApi";

import { Accordion, Form, Icon, Dropdown } from "semantic-ui-react";
import UNIV from "../../../constants/univ";

let timer = null;

export default class Searchbox extends React.Component {
  static View = SearchboxView;

  constructor(props) {
    super(props);
    const { univ_id = "" } = this.props;
    this.state = {
      isDetailActive: false,
      values: {
        keyword: [],
        teacher: [],
        course: "",
        course_group: "",
        semester: "",
      },
      options: {
        selected: {
          // 選択した選択肢を格納する
          keyword: [],
          teacher: [],
          course: "",
          // course_group: [],
          // semester: [],
        },
        results: {
          // DBから取得した選択肢を格納する
          keyword: [],
          teacher: [],
          course: [],
          course_group: UNIV[univ_id].course_group.map((elm) =>
            this.createOption(elm)
          ),
          semester: UNIV[univ_id].periods.map((elm) => this.createOption(elm)),
        },
      },
      loading: {
        keyword: false,
        teacher: false,
        course: false,
        course_group: false,
        semester: false,
      },
    };
  }

  typing_timer = null;

  componentDidMount = () => {
    this.setQueryParams();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      // クエリパラメータが変更されたとき
      this.setQueryParams();
    }
  };

  createOption = (value) => {
    return { key: value, text: value, value: value };
  };

  setQueryParams = () => {
    // クエリパラメータで渡ってきた値をセット
    const params = new URLSearchParams(this.props.location.search);
    const queries = {
      keyword: params.get("keyword")?.split(",") || [],
      teacher: params.get("teacher")?.split(",") || [],
      course: params.get("course") || "",
      course_group: params.get("course_group") || "",
      semester: params.get("semester") || "",
    };
    this.setState({
      values: queries,
      options: {
        ...this.state.options,
        selected: {
          ...this.state.options.selected,
          keyword: queries.keyword.map((elm) => this.createOption(elm)),
          teacher: queries.teacher.map((elm) => this.createOption(elm)),
          course: this.createOption(queries.course),
        },
      },
    });

    // 詳細検索が有効なとき
    if (
      queries.teacher.length > 0 ||
      queries.course ||
      queries.course_group ||
      queries.semester
    ) {
      this.setState({
        isDetailActive: true,
      });
    }
  };

  // 入力値が変化したときの処理
  onSearchChange = (e, obj) => {
    const { name, searchQuery } = obj;
    const univId = this.props.univ_id;
    switch (name) {
      case "keyword":
        if (searchQuery.length === 0) {
          this.setState({
            options: {
              ...this.state.options,
              results: {
                ...this.state.options.results,
                [name]: [],
              },
            },
            loading: {
              ...this.state.loading,
              [name]: false,
            },
          });
          break;
        }
        clearTimeout(timer);
        this.setState({ loading: { ...this.state.loading, [name]: true } });
        timer = setTimeout(() => {
          const courseApi = new CourseApi();
          const teacherApi = new TeacherApi();
          const currentValue = searchQuery;
          const newOptions = [];
          // 講義名で検索
          courseApi
            .getUnivCourses(univId, currentValue)
            .then((res) => {
              const courses = res.map((course) => course.name);
              const courseOptions = Array.from(new Set(courses))
                .map((course) => this.createOption(course))
                .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
              newOptions.push(...courseOptions);
              // 教員名で検索
              teacherApi.getUnivTeachers(univId, currentValue).then((res) => {
                const teacherOptions = res
                  .map((teacher) => this.createOption(teacher.name))
                  .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
                newOptions.push(...teacherOptions);
                // resultsを更新
                this.setState({
                  options: {
                    ...this.state.options,
                    results: {
                      ...this.state.options.results,
                      [name]: newOptions,
                    },
                  },
                });
              });
            })
            .finally(() => {
              this.setState({
                loading: { ...this.state.loading, [name]: false },
              });
            });
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      case "teacher":
        if (searchQuery.length === 0) {
          this.setState({
            options: {
              ...this.state.options,
              results: {
                ...this.state.options.results,
                [name]: [],
              },
            },
            loading: {
              ...this.state.loading,
              [name]: false,
            },
          });
          break;
        }
        clearTimeout(timer);
        this.setState({ loading: { ...this.state.loading, [name]: true } });
        timer = setTimeout(() => {
          const teacherApi = new TeacherApi();
          const currentValue = [...this.state.values.teacher, searchQuery];
          // 教員名を検索してオートコンプリート
          teacherApi
            .getUnivTeachers(univId, searchQuery)
            .then((res) => {
              const teachers = res.map((teacher) => teacher.name);
              const teacherOptions = teachers
                .map((teacher) => this.createOption(teacher))
                .filter((option) => !currentValue.includes(option.value)); // 現在の値と同じものはoptionが重複するので除外
              this.setState({
                options: {
                  ...this.state.options,
                  results: {
                    ...this.state.options.results,
                    [name]: teacherOptions,
                  },
                },
              });
            })
            .finally(() => {
              this.setState({
                loading: { ...this.state.loading, [name]: false },
              });
            });
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      case "course":
        if (searchQuery.length === 0) {
          this.setState({
            options: {
              ...this.state.options,
              results: {
                ...this.state.options.results,
                [name]: [],
              },
            },
            loading: {
              ...this.state.loading,
              [name]: false,
            },
          });
          break;
        }
        clearTimeout(timer);
        this.setState({ loading: { ...this.state.loading, [name]: true } });
        timer = setTimeout(() => {
          const courseApi = new CourseApi();
          courseApi
            .getUnivCourses(univId, searchQuery)
            .then((res) => {
              const currentValue = this.state.values.course;
              const courses = Array.from(
                new Set(res.map((course) => course.name))
              ); // 重複を削除
              const courseOptions = courses
                .map((course) => this.createOption(course))
                .filter((option) => option.value !== currentValue); // 現在の値と同じものはoptionが重複するので除外
              this.setState({
                options: {
                  ...this.state.options,
                  results: {
                    ...this.state.options.results,
                    [name]: courseOptions,
                  },
                },
              });
            })
            .finally(() => {
              this.setState({
                loading: { ...this.state.loading, [name]: false },
              });
            });
          this.setState({
            loading: { ...this.state.loading, [name]: false },
          });
        }, 1000); // 1秒間入力がなければAPIを叩く
        break;
      default:
        break;
    }
  };

  // 選択した値が変化したときの処理
  onFormChange = (e, { name, value }) => {
    switch (name) {
      case "keyword":
        this.setState({
          options: {
            selected: {
              ...this.state.options.selected,
              [name]: value.map((elm) => this.createOption(elm)),
            },
            results: {
              ...this.state.options.results,
              [name]: [],
            },
          },
        });
        break;
      case "teacher":
        if (value.length > 0 && typeof value[value.length - 1] === "object") {
          // valueが文字列ではなく配列になっているoptionを選択したとき
          value = value[value.length - 1];
        }
        this.setState({
          options: {
            selected: {
              ...this.state.options.selected,
              [name]: value.map((elm) => this.createOption(elm)),
            },
            results: {
              ...this.state.options.results,
              [name]: [],
            },
          },
        });
        break;
      case "course":
        this.setState({
          options: {
            selected: {
              ...this.state.options.selected,
              [name]: this.createOption(value),
            },
            results: {
              ...this.state.options.results,
              [name]: [],
            },
          },
        });
        break;
      default:
        break;
    }
    // valuesの更新
    const newState = {
      ...this.state,
      values: { ...this.state.values, [name]: value },
    }; // setStateだとすぐに変更が反映されないので変数に代入

    const queryParams = new URLSearchParams();
    const { values } = newState;
    const { univ_id, pushHistory } = this.props;
    // 空文字ならクエリパラメータに追加しない
    if (values.keyword.length > 0)
      queryParams.append("keyword", values.keyword.join(","));
    if (values.teacher.length > 0)
      queryParams.append("teacher", values.teacher.join(","));
    if (values.course) queryParams.append("course", values.course);
    if (values.course_group)
      queryParams.append("course_group", values.course_group);
    if (values.semester) queryParams.append("semester", values.semester);

    const search = queryParams.toString() ? `?${queryParams.toString()}` : "";
    pushHistory(
      `${ROUTES.APP.REVIEW.SEARCH.replace(":univ_id", univ_id)}${search}`
    );
  };

  handleDetailClick = (e) => {
    this.setState({ isDetailActive: !this.state.isDetailActive });
  };

  render() {
    const {
      placeholder = "例: 田中太郎, 基礎セミナー",
      showDetail = false,
      ...otherProps
    } = this.props;
    const { values, options, loading } = this.state;
    const { selected, results } = options;
    return (
      <>
        <Form>
          <Form.Field>
            <Dropdown
              name="keyword"
              options={[...selected.keyword, ...results.keyword]}
              value={values.keyword}
              placeholder="例: 田中 太郎, 基礎セミナー"
              loading={loading.keyword}
              fluid
              multiple
              search
              selection
              clearable
              scrolling
              closeOnChange
              allowAdditions
              additionLabel="検索条件に追加: "
              noResultsMessage="検索キーワードを入力してください"
              icon={
                <Icon
                  name="search"
                  style={{ transform: "translate(0, -3px)" }} // 位置ズレ修正
                />
              }
              onSearchChange={this.onSearchChange}
              onChange={this.onFormChange}
            />
          </Form.Field>
        </Form>
        {showDetail && (
          <Accordion>
            <Accordion.Title
              active={this.state.isDetailActive}
              onClick={this.handleDetailClick}
              style={{
                textAlign: "center",
                color: "gray",
                margin: "10px 0px -2px",
                padding: "0px",
              }}
            >
              <Icon name="sliders horizontal" />
              詳細検索
              {this.state.isDetailActive ? (
                <Icon name="angle up" />
              ) : (
                <Icon name="angle down" />
              )}
            </Accordion.Title>
            <Accordion.Content active={this.state.isDetailActive}>
              <Form style={{ margin: "10px 0px" }}>
                <Form.Dropdown
                  label="教員名"
                  name="teacher"
                  options={[...selected.teacher, ...results.teacher]}
                  value={values.teacher}
                  placeholder="田中 太郎"
                  loading={loading.teacher}
                  fluid
                  multiple
                  search
                  selection
                  clearable
                  scrolling
                  closeOnChange
                  allowAdditions
                  additionLabel="検索条件に追加: "
                  noResultsMessage="検索キーワードを入力してください"
                  icon={
                    <Icon
                      name="search"
                      style={{ transform: "translate(0, -3px)" }} // 位置ズレ修正
                    />
                  }
                  onSearchChange={this.onSearchChange}
                  onChange={this.onFormChange}
                />
                <Form.Dropdown
                  label="講義名"
                  name="course"
                  options={[
                    ...(values.course !== "" ? [selected.course] : []),
                    ...results.course,
                  ]}
                  value={values.course}
                  placeholder="物理学基礎"
                  loading={loading.course}
                  fluid
                  search
                  selection
                  clearable
                  scrolling
                  closeOnChange
                  allowAdditions
                  additionLabel="検索条件に追加: "
                  noResultsMessage="検索キーワードを入力してください"
                  icon={
                    <Icon
                      name="search"
                      style={{ transform: "translate(0, -3px)" }} // 位置ズレ修正
                    />
                  }
                  onSearchChange={this.onSearchChange}
                  onChange={this.onFormChange}
                />
                <Form.Select
                  label="科目区分"
                  name="course_group"
                  options={results.course_group}
                  value={values.course_group}
                  placeholder="理系基礎科目"
                  loading={loading.course_group}
                  clearable
                  onChange={this.onFormChange}
                />
                <Form.Select
                  label="開講学期"
                  name="semester"
                  options={results.semester}
                  value={values.semester}
                  placeholder="前期"
                  loading={loading.semester}
                  clearable
                  onChange={this.onFormChange}
                />
              </Form>
            </Accordion.Content>
          </Accordion>
        )}
      </>
    );
  }
}