import React from "react";

import Navbar from "../../organisms/Navbar/index";
import Footer from "../../organisms/Footer/index";

import * as ROUTES from "../../../constants/routes";

import Page from "./Page";

export default class PageTemplate extends React.Component {
  pushHistory = (uri) => {
    if (this.props.history) {
      this.props.history.push(uri);
    }
  };
  renderPage = () => {
    const { path = "" } = this.props.match;
    switch (path) {
      case ROUTES.PRIVACY:
        return <Page.Privacy />;
      case ROUTES.TERMS:
        return <Page.Terms />;
      case ROUTES.GUIDE:
        return <Page.Guide />;
      case ROUTES.NOT_FOUND:
        return <Page.NotFound />;
      default:
        return <Page.NotFound />;
    }
  };
  render() {
    const { children, ...otherProps } = this.props;

    console.log(this.props.match.path);
    return (
      <div {...otherProps}>
        <Navbar
          style={{ marginBottom: "24px" }}
          univ_id={""}
          subtitle={"授業評価"}
          show_pusher={false}
          show_control={false}
          pushHistory={this.pushHistory}
        />
        {this.renderPage()}
        <Footer pushHistory={this.pushHistory} hide_desert />
      </div>
    );
  }
}