import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";

import Navbar from "../../organisms/Navbar";
import Footer from "../../organisms/Footer";
import TabMenu from "../../../features/timetable/components/TabMenu";
import MyTimeTable from "../../../features/timetable/components/MyTimeTable/index";

import UNIV from "../../../constants/univ";
import { useMyTimeTables } from "../../../features/timetable/hooks/timetable/useMyTimeTables";
import { useCognitoUser, useRakudaUser } from "../../../hooks/user";
import { useTerm } from "../../../features/timetable/hooks/timetable/useTerm";

const TimeTablePageTemplate = (props) => {
  const { location } = props;
  const { user: cognitoUser } = useCognitoUser();
  const userId = cognitoUser ? cognitoUser.attributes.sub : null;
  const {
    myTimeTables,
    isLoading: isTTLoading,
    setMyTimeTables,
    getMyTimeTable,
    createMyTimeTable,
    updateMyTimeTable,
  } = useMyTimeTables(userId);
  const { user, isLoading: isUserLoading, error } = useRakudaUser(userId);
  const univ_id = user ? user.univ.univ_id : null;
  const { term, setTerm } = useTerm(univ_id);
  const [grade, semester] = term;
  const history = useHistory();

  const grades = [1, 2, 3, 4];
  const semesters = UNIV[univ_id] ? UNIV[univ_id].semesters : [];
  const terms = [];
  grades.forEach((grade) => {
    semesters.forEach((semester) => {
      if (grade && semester) {
        terms.push([grade, semester]);
      }
    });
  });

  useEffect(() => {
    if (univ_id) {
      // クエリパラメータで学年と学期が指定されていれば設定
      const params = new URLSearchParams(location.search);
      const gradeParam = params.get("grade") && Number(params.get("grade"));
      const semesterParam = params.get("semester");
      const termExists = terms.some((term) => {
        const [grade, semester] = term;
        return grade === gradeParam && semester === semesterParam;
      });
      if (termExists) {
        setTerm([gradeParam, semesterParam]);
      } else {
        // パラメータが設定されていなければ現在の学年をgradeに設定
        const date = new Date();
        date.setMonth(date.getMonth() - 3);
        const currentYear = date.getFullYear(); // 年度を取得
        const userGrade = user?.grade
          ? currentYear - Number(user.grade) + 1
          : 1; // 学年（年度）から学年（年）を取得
        const semester = terms[0][1]; // 第1学期を取得
        // 学年が1~4年生の場合のみ設定
        if (0 < userGrade && userGrade <= 4 && semester) {
          setTerm([userGrade, semester]);
        }
      }
    }
  }, [univ_id]);

  useEffect(() => {
    if (univ_id) {
      if (grade && semester) {
        history.push({
          search: `?grade=${grade}&semester=${semester}`,
        });
      }
    }
  }, [grade, semester]);

  const handleChangeMyTimeTable = (timetable) => {
    updateMyTimeTable(timetable)
      .then(() => {
        const newTimeTables = myTimeTables.map((tt) =>
          tt.grade === timetable.grade && tt.semester === timetable.semester
            ? timetable
            : tt
        );
        setMyTimeTables(newTimeTables); // 通信処理削減のためフロント側で更新
      })
      .catch((err) => {
        console.error(err);
        window.alert("時間割の更新に失敗しました。");
      });
  };

  const handleCreateMyTimeTable = (timetable) => {
    createMyTimeTable(timetable)
      .then((newTimeTable) => {
        const table_id = newTimeTable.my_timetable_id;
        getMyTimeTable(table_id)
          .then((timetable) => {
            setMyTimeTables([...myTimeTables, timetable]);
          })
          .catch((err) => {
            console.error(err);
            window.alert("時間割の取得に失敗しました。");
          });
      })
      .catch((err) => {
        console.error(err);
        window.alert("時間割の作成に失敗しました。");
      });
  };

  const { department, major } = user ? user : { department: null, major: null };
  const timetable = myTimeTables.find(
    (tt) => tt.grade === grade && tt.semester === semester
  );
  return (
    <>
      <Helmet>
        <title>楽単らくだ｜My時間割</title>
        <meta
          name="description"
          content={
            "「楽単らくだ」は、履修選択に悩める大学生のための情報共有プラットフォームです。学生から収集した授業の採点方法、単位の取りやすさなどの授業評価・レビューを共有。「鬼仏表」や「逆評定」としての利用も可能です。"
          }
        />
        <meta name="robots" content="index" />
      </Helmet>
      <Navbar
        style={{ marginBottom: "24px" }}
        subtitle="授業評価"
        show_pusher={false}
        show_control={false}
        pushHistory={history.push}
      />
      <Container text>
        <TabMenu />
        <MyTimeTable
          univ_id={univ_id}
          department={department}
          major={major}
          timetable={timetable}
          currentTerm={term}
          terms={terms}
          loading={isTTLoading || isUserLoading || error}
          onCreateTimeTable={handleCreateMyTimeTable}
          onChangeTimeTable={handleChangeMyTimeTable}
          onChangeTerm={setTerm}
          limited={cognitoUser ? false : true}
        />
      </Container>
      <Footer pushHistory={history.push} />
    </>
  );
};

export default TimeTablePageTemplate;